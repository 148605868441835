const filters = ['Makers','Cacao Growers and Distributors','Retailers & Subscriptions','Equipment','Other Related Business','Events','Researchers', 'Cacao Facts', 'Historical Facts', 'Regional Facts', 'Fun Facts'];

const default_filters = {
	'Makers':true,
	'Cacao Growers and Distributors':true,
	'Retailers & Subscriptions':true,
	'Equipment':true,
	'Other Related Business':true,
	'Events':true,
	'Researchers': true,
	'Cacao Facts': false, 
	'Historical Facts': false, 
	'Regional Facts': false, 
	'Fun Facts': false
}

let my_filters = Object.assign({}, default_filters);

export default function(state=my_filters, action) {

	switch (action.type) {
		case "TOGGLE_FILTER": 
			my_filters[action.val] = !my_filters[action.val];
			console.log(my_filters);
			break;
		case "RESET_FILTERS":
			my_filters = Object.assign({}, default_filters);
			break;
		default:
			return state
	}

	return my_filters;
}
