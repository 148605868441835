import {combineReducers} from 'redux';
import filtersReducer from './filtersReducer.js';
import markersReducer from './markersReducer.js';
import mapMarkersReducer from './mapMarkersReducer.js';
import searchResultReducer from './searchResultReducer.js';
const allReducers= combineReducers({
	filters: filtersReducer,
	all_markers: markersReducer,
	search_result: searchResultReducer,
	markers: mapMarkersReducer,
});
export default allReducers;
