let search_result = {};
export default function(state=search_result, action) {

	switch (action.type) {
		case "SET_SEARCH_RESULT": 
			search_result = action.m;
			break;
		case "TOGGLE_FILTER":
			search_result = {};
			break;
		default:
			return state
	}

	return search_result;
}
