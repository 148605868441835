let all_markers = [];
export default function(state=all_markers, action) {

	switch (action.type) {
		case "SET_MARKERS": 
			all_markers = action.all_markers;
			break;
		default:
			return state
	}

	return all_markers;
}
