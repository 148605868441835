import React from 'react';
import {Map, InfoWindow, Marker, GoogleApiWrapper} from 'google-maps-react';
import allReducers from './reducers/index.js';
import {createStore} from 'redux';
import {Provider} from 'react-redux';

const store = createStore(allReducers);

const filters = ['Makers','Cacao Growers and Distributors','Retailers & Subscriptions','Equipment','Other Related Business','Events','Researchers', 'Cacao Facts', 'Historical Facts', 'Regional Facts', 'Fun Facts'];
var cat_short = {
    'Makers': 'Makers',
    'Cacao Growers and Distributors': 'Cacao',
    'Retailers & Subscriptions': 'Retailer',
    'Equipment': 'Equipment',
    'Other Related Business': 'Other',
    'Events': 'Events',
    'Researchers': 'Research',
    'Cacao Facts': 'Cacao Facts',
    'Historical Facts': 'Historical',
    'Regional Facts': 'Regional',
    'Fun Facts': 'Fun Facts',
};


export class MapContainer extends React.Component {
    constructor(props) {
		super(props);
		this.state = {
			zoom: 3,
			region: {
				lat: 49.232323,
				lng: -123.12212
            },
            filters: {},
            markers: [],
			selectedMarker: false,
			showImage: false,
            all_markers: [],
			showFilters: false,
			searchTerm: false,
			searchResults: []
		};

        this.selectMarker = this.selectMarker.bind(this); // <-- makes available 'this' in onToken scope
        this.clearMarker = this.clearMarker.bind(this); // <-- makes available 'this' in onToken scope
        this.toggleFilters = this.toggleFilters.bind(this); // <-- makes available 'this' in onToken scope
        this.updateSearch = this.updateSearch.bind(this); // <-- makes available 'this' in onToken scope
		this.showImage = this.showImage.bind(this);
		this.closeImage = this.closeImage.bind(this);
	}
	componentDidMount() {
    this.unsubscribeStore = store.subscribe(this.updateStateFromStore);
        (async function() {
            const response = await fetch('https://sheets.googleapis.com/v4/spreadsheets/1MwDq40uUc77hokYpMkLAfLd9Z4of_SiTv_K15P0C8es/values/A1:N2000?alt=json&key=AIzaSyAJozwPOUIIgzhzEZX5vRbAWw9MC8Ktqkk')
            const data = await response.json();

            var colors = {
                'Makers': 'c2185b',
                'Cacao Growers and Distributors': '558b2f',
                'Retailers & Subscriptions': 'f97f25',
                'Equipment': '8A2BE2',
                'Other Related Business': '01579b',
                'Events': '0097a7',
                'Researchers': '795548',
                'Cacao Facts': 'ec7063',
                'Historical Facts': 'af7ac5',
                'Regional Facts': '5dade2',
                'Fun Facts': 'e67e22'
            };

            var markers = [];
            var i = 0;

			const fields = data.values.shift();

            data.values.forEach(function(item_row) {
				let item = {};
				for (let i=0; i<fields.length; i++) {
					item[fields[i]] = (item_row[i]) ? item_row[i] : '';
				}

                var latlng = item['Location'].split(/,/);

				var images = item['Images'].split(/,/).filter(function(i) { return i.trim() != '' }).map(function(i) { return i.trim() });

                var marker = {
                    key: i,
                    category: item['Category'],
                    city: item['City Name'],
					color: (item['Category'] == 'Chocolate Facts') ? colors[item['Secondary Maker']] : colors[item['Category']],
                    title: item['Name'],
                    url: item['Website'],
                    promo: item['Promo'],
                    promodesc: item['Promo Desc'],
					images: images,
                    description: item['Description'],
                    onlineshop: item['Online Shop?'],
                    physicalshop: item['Physical Shop'],
                    secondarymaker: item['Secondary Maker'],
                    secondarypopup: item['Secondary Popup'],
                    latlng: {
                        lat: parseFloat(latlng[0]),
                        lng: parseFloat(latlng[1])
                    }
                }

                markers.push(marker);
                i++;
            });

            var all_markers = markers.slice();
            var initial_markers = all_markers.filter(element => element.category != 'Chocolate Facts' )

            store.dispatch({ type: 'SET_MARKERS', all_markers });
            this.setState( { markers: initial_markers, initital_load:  true });
        }.bind(this))();

        getCurrentLocation().then(position => {
          if (position) {
            this.setState({
              region: {
                lat: position.coords.latitude,
                lng: position.coords.longitude
              },
            });
          }
        });
	}

	updateStateFromStore = () => {
		const currentState = this.getCurrentStateFromStore();

		var markers = [];

		this.state.all_markers.forEach(function(m) {
			if (currentState.filters[m.category] || currentState.filters[m.secondarymaker]) {
				markers.push(m);
			}
		});


		currentState.markers = markers;
		this.setState(currentState);
	}


	getCurrentStateFromStore() {
		return store.getState()
	}

	clearFilters() {
		this.setState({ showFilters: false } );
	}
	clearMarker(event) {
        this.setState({ selectedMarker: false });
    }
	clearSearch() {
        this.setState({ searchTerm: false, searchResults: [] });
	}
	toggleFilters(event) {
        this.setState({ showFilters: !this.state.showFilters });
	}
	updateFilter(val) {
        store.dispatch({ type: 'TOGGLE_FILTER', val });
    }
	selectMarker(marker) {
        this.setState({ searchTerm: false, searchResults: [], selectedMarker: marker, region: marker.latlng, zoom: 14 });
	}
	showImage(e) {
		this.setState({ showImage: e.target.src });
	}
	closeImage(e) {
		this.setState({ showImage: false });
	}

	updateSearch(e) {
		var search_term = e.target.value;
        var search_results = [];
        var re = new RegExp(''+search_term+'', 'i');

        this.state.all_markers.forEach(function(m) {
            if (m.title.match(re)) {
                search_results.push(m);
            }
        });

        this.setState({ searchResults: search_results, searchTerm: search_term });
	}

  render() {
	var markers = [];

	var infowindow;
	for (var i=0; i<this.state.markers.length; i++) {
		var marker;
		(function(m) {
			var icon = 'https://chart.googleapis.com/chart?chst=d_map_pin_icon&chld=2|0|'+this.state.markers[i].color+'|90|_|%E2%80%A2';
			marker = <Marker key={'marker='+this.state.markers[i].key} markerId={this.state.markers[i].key} onClick={ (marker) => { this.selectMarker(m) }} icon={{ path: 'M 0,0 C -2,-20 -10,-22 -10,-30 A 10,10 0 1,1 10,-30 C 10,-22 2,-20 0,0 z', fillColor: '#'+this.state.markers[i].color, 
				fillOpacity: 1,
            strokeColor: '#000',
            strokeWeight: 2,
            scale: 1
 }} name={this.state.markers[i].title} position={this.state.markers[i].latlng} />;
		}.bind(this))(this.state.markers[i]);

		markers.push(marker);
	}

	var center = this.state.region;

	var infoBox = false;

	var showImage = false;

	if (this.state.selectedMarker) {

		var secondary_maker = this.state.selectedMarker.secondarymaker;
		var website = false;
		var website_title = (this.state.selectedMarker.category == 'Chocolate Facts') ? 'Learn More' : 'Website';


		if (this.state.selectedMarker.secondarypopup) {
			secondary_maker = <a href="#0" onClick={function() { window.alert(this.state.selectedMarker.secondarypopup) }.bind(this)}>{secondary_maker}</a>;
		}
		if (this.state.selectedMarker.url && this.state.selectedMarker.promo && 0) {
			website = <div className="app-info-box-row">
				<div className="app-info-box-website"><a target="_blank" href={this.state.selectedMarker.url}>{website_title}</a></div>
				<div className="app-info-box-promo"><a href="#0" onClick={ function() {window.alert(this.state.selectedMarker.promo)}.bind(this)}>{this.state.selectedMarker.promo}</a></div>
			</div>;	
		}
		else if (this.state.selectedMarker.url) {
			website = <div>
				<div className="app-info-box-website"><a target="_blank" href={this.state.selectedMarker.url}>{website_title}</a></div>
			</div>;	
		}
		else if (this.state.selectedMarker.promo && 0) {
			website = <div className="app-info-box-row">
				<div className="app-info-box-promo"><a href="#0" onClick={ function() {window.alert(this.state.selectedMarker.promo)}.bind(this)}>{this.state.selectedMarker.promo}</a></div>
			</div>;	
		}

		var images;

		if (this.state.selectedMarker.images.length > 0) {
			var cnt = 0;
			var image_solo = this.state.selectedMarker.images.map(function(i) {
				cnt++;
				return <li key={"image:"+cnt}><img src={i} onClick={this.showImage} /></li>
			}.bind(this));

			images = <div className="company-images"><ul>{image_solo}</ul></div>;
		}


		center = this.state.selectedMarker.latlng;



		if (this.state.selectedMarker.category == 'Chocolate Facts') {
			infoBox = <div className="app-info-box">
				<div className="close" onClick={this.clearMarker}><span>&times;</span></div>
				<h1>{this.state.selectedMarker.title}</h1>
				<h2><a href={"https://maps.google.ca/?q="+this.state.selectedMarker.latlng.lat+','+this.state.selectedMarker.latlng.lng} target="_blank">{this.state.selectedMarker.city}</a></h2>
				{images}
				<div className="app-info-box-row">
					<div>Chocolate Facts</div>
					<div>{secondary_maker}</div>
				</div>
				{website}
				<div className="app-info-box-desc">{this.state.selectedMarker.description}</div>
			</div>;

		}
		else {
			infoBox = <div className="app-info-box">
				<div className="close" onClick={this.clearMarker}><span>&times;</span></div>
				<h1>{this.state.selectedMarker.title}</h1>
				<h2><a href={"https://maps.google.ca/?q="+this.state.selectedMarker.latlng.lat+','+this.state.selectedMarker.latlng.lng} target="_blank">{this.state.selectedMarker.city}</a></h2>
				{images}
				<div className="app-info-box-row">
					<div>{cat_short[this.state.selectedMarker.category]}</div>
					<div>Storefront? <span>{this.state.selectedMarker.physicalshop}</span></div>
				</div>
				<div className="app-info-box-row">
					<div>{secondary_maker}</div>
					<div>Online Store? <span>{this.state.selectedMarker.onlineshop}</span></div>
				</div>
				{website}
				<div className="app-info-box-desc">{this.state.selectedMarker.description}</div>
			</div>;
	}

		if (this.state.showImage) {
			showImage = <div className="single-image"><div className="close-image" onClick={this.closeImage}><span>&times;</span></div><img src={this.state.showImage} /></div>;
		}
	}

	var filters = false;
	if (this.state.showFilters) {
		filters = <div className="app-filters">
			<ul>
				<li><h2>Business Filters</h2></li>
				<li><label htmlFor="filter1">Bean To Bar Makers</label> <input type="checkbox" id="filter1" value="Makers" checked={this.state.filters['Makers']} onChange={ () => { this.updateFilter('Makers'); }} /></li>
				<li><label htmlFor="filter2">Retailers & Subscriptions</label> <input type="checkbox" id="filter2" value="Retailers & Subscriptions" checked={this.state.filters['Retailers & Subscriptions']} onChange={ () => { this.updateFilter('Retailers & Subscriptions'); }}   /></li>
				<li><label htmlFor="filter3">Events</label> <input type="checkbox" id="filter3" value="Events" checked={this.state.filters['Events']} onChange={ () => { this.updateFilter('Events'); }}  /></li>
				<li><label htmlFor="filter4">Related Business</label> <input type="checkbox" id="filter4" value="Other Related Business" checked={this.state.filters['Other Related Business']} onChange={ () => { this.updateFilter('Other Related Business'); }}  /></li>
				<li><label htmlFor="filter5">Cacao Suppliers</label> <input type="checkbox" id="filter5"  value="Cacao Growers and Distributors" checked={this.state.filters['Cacao Growers and Distributors']} onChange={ () => { this.updateFilter('Cacao Growers and Distributors'); }}  /></li>
				<li><label htmlFor="filter6">Equipment</label> <input type="checkbox" id="filter6" value="Equipment" checked={this.state.filters['Equipment']} onChange={ () => { this.updateFilter('Equipment'); }}  /></li>
				<li><label htmlFor="filter7">Researchers</label> <input type="checkbox" id="filter7" value="Researchers" checked={this.state.filters['Researchers']} onChange={ () => { this.updateFilter('Researchers'); }}  /></li>
				<li><h2>Facts Filters</h2></li>
				<li><label htmlFor="filter8">Cacao Facts</label> <input type="checkbox" id="filter8" value="Cacao Facts" checked={this.state.filters['Cacao Facts']} onChange={ () => { this.updateFilter('Cacao Facts'); }}  /></li>
				<li><label htmlFor="filter9">Historical Facts</label> <input type="checkbox" id="filter9" value="Historical Facts" checked={this.state.filters['Historical Facts']} onChange={ () => { this.updateFilter('Historical Facts'); }}  /></li>
				<li><label htmlFor="filter10">Regional Facts</label> <input type="checkbox" id="filter10" value="Regional Facts" checked={this.state.filters['Regional Facts']} onChange={ () => { this.updateFilter('Regional Facts'); }}  /></li>
				<li><label htmlFor="filter11">Fun Facts</label> <input type="checkbox" id="filter11" value="Fun Facts" checked={this.state.filters['Fun Facts']} onChange={ () => { this.updateFilter('Fun Facts'); }}  /></li>
			</ul>
		</div>;
	}

	var search_results = false;
	if (this.state.searchTerm) {
		var search_results_list = [];
		for (var i=0; i<this.state.searchResults.length; i++) {
			(function(marker) {
				search_results_list.push(<li key={"search-"+this.state.searchResults[i].key} onClick={ () => { this.selectMarker(marker); } }>{this.state.searchResults[i].title}</li>);
			}.bind(this))(this.state.searchResults[i]);
		}
		search_results = <div className="app-search-results"><ul>{search_results_list}</ul></div>;
	}



    return (
	<div>
	<div className="app-main">
		<div className="app-filters-button" onClick={this.toggleFilters}><i className="icon-settings"></i></div>
		<div className="app-search">
			<div className="app-search-inner">
				<input type="text" placeholder="Search..." onChange={this.updateSearch} value={(this.state.searchTerm) ? this.state.searchTerm : ''} />
				{search_results}
			</div>
		</div>
	</div>
      <Map google={this.props.google} initialCenter={this.state.region} center={center} zoom={this.state.zoom} className="app-map" onClick={() => { this.clearMarker(); this.clearFilters(); }}>

		{markers}
      </Map>
		{filters}
		{infoBox}
		{showImage}
	</div>
    );
  }
}

export const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(position => resolve(position), e => reject(e));
  });
};


function generateRandom() {
  return Math.random() * 10000000000000000
}


export default GoogleApiWrapper({
  apiKey: 'AIzaSyCZb4dr350bq2r83kITQuK6gsZmbUQ9Q_8'
})(MapContainer)

/*
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

export default App;
*/
